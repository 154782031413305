import React, { useEffect } from "react";
import {ReactComponent as HeroBg} from "../img/hero_bg2.svg";
import Navbar from "./navbar";
import BG from "../img/homebg2.png"
import Logo from "../img/logowtextinvis.png"
export default function Hero (props){
    return(
        
        props.home==true ? <div id="hero" style={{height:"100vh",overflow:"hidden", position:"relative", margin:"auto"}}>
                <Navbar/>
                <img src={BG} alt="" style={{minWidth:"1920px",width:"100%",height:"100%",top:"0",left:"0", position:"absolute", zIndex:"-1000"}}/>
                <div style={{display:"flex", height:"100%"}} >
                    <img src={Logo} className="m-auto" alt="" style={{width:"50%",margin:"auto", minWidth:"300px", maxWidth:"500px"}}/>
                </div>
            </div>
            :
            <div id="hero" style={{width:"100%",height:"300px",overflow:"hidden", position:"relative", margin:"auto"}}>
                <HeroBg style={{position:"absolute", maxHeight:"400px", minHeight:"300px", top:"0", left:"0", zIndex:"-1000"}}/>
                {/* <Image src={logo} style={{width:"45px",position:"absolute",top:"10px", left:"10px"}}/> */}
                <Navbar />							
                <div className="header-title">{props.title}</div>
            </div>

    )
}