import React from "react";
import { Button } from "react-bootstrap";
import MyButton from "./../mybutton";
import { Form, Container, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useContext, useEffect } from "react";
import LSSLogo from "../../img/LSSlogo.png";
import AuthContext from "../../context/authprovider";
export default function CoP(props) {
	let params = useParams();
	const { auth, setAuth } = useContext(AuthContext);
	const [npm, setNpm] = useState(true);
	const [yc, setYc] = useState(false);

	// const [password,setPassword]=useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [message, setMessage] = useState("");
	const style = {
		maxWidth: "800px",
		textAlign: "center",
		margin: "auto",
		boxShadow: "5px 5px 5px grey",
	};
	useEffect(() => {
		let saved = localStorage.getItem("user");
		if (saved && JSON.stringify(auth) === "{}") {
			setAuth(JSON.parse(saved));
		}
	}, []);
	useEffect(() => {}, []);
	return (
		<div className="steps-container">
			<div style={{ marginBottom: "20px" }}>
				<Container>
					<Row>
						<div
							style={{
								width: "100%",
								textAlign: "center",
								marginBottom: "30px",
							}}
						>
							<img
								style={{ width: "20%", textAlign: "center" }}
								src={LSSLogo}
							/>
						</div>
					</Row>
					<Row>
						<div className="cop-container npm">
							<div className="header-text">
								2024 Leadership & Sustainability School Leaders' Summit{" "}
							</div>
							<div className="date-text">In-Person April 17-19, 2024</div>
							<p style={{ marginBottom: "30px" }}>
								The Leaders' Summit is intended to draw out the experiences,
								perspectives, and opinions of all participants through a dynamic
								combination of panel discussions, experiential exercises, a
								small-group project, and participant presentations.
							</p>
							<p>
								By the close of the summit, participants will be able to:
								<ul>
									<li>
										Integrate their current leadership strengths and personal
										leadership philosophy into their current commercial tobacco
										control program priorities;
									</li>
									<li>
										Examine current opportunities and challenges to{" "}
										<strong>DRIVING SUSTAINABLE CHANGE</strong> in tobacco
										control using the tools and frameworks outlined in A
										Beautiful Constraint;
									</li>
									<li>
										Using a <strong>SHARED LANGUAGE FOR HEALTH EQUITY</strong>,
										discuss how institutions and systems are contributing to
										unjust and inequitable health outcomes;
									</li>
									<li>
										Apply <strong>PRINCIPLES OF HEALTH EQUITY</strong> into all
										elements of their leadership practice, including personal,
										institutional and structural-level approaches to equity in
										commercial tobacco control; and
									</li>
									<li>
										Make strategic use of a powerful <strong>NETWORK</strong> of
										like-minded emerging leaders, OSH staff and current
										commercial tobacco control leaders.
									</li>
								</ul>
							</p>
						</div>
					</Row>
				</Container>
			</div>
		</div>
	);
}
