import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Webinar from "../img/webinars2.jpg";
import Training from "../img/training.jpg";
import Bp from "../img/bestpractices.jpg";
import Reference from "../img/refmaterials.jpg";
import Sgr from "../img/sgr.jpg";
import Ppt from "../img/ppti.jpeg";
import AuthContext from "../context/authprovider";
import { Form } from "react-bootstrap";
import { useContext } from "react";

export default function QuestionBox(props) {
	const { auth } = useContext(AuthContext);
	const [active, setActive] = useState(false);
	const [description, setDescription] = useState(false);
	// const [titleStyle,setTitleStyle]=useState({});
	const [color, setColor] = useState("");
	const [bgColor, setBgColor] = useState("");
	const [bgImage, setBgImage] = useState();
	const [display, setDisplay] = useState("");
	const [isShown, setIsShown] = useState(true);
	const clickHandler = () => {
		setActive(!active);
		// console.log(active);
		if (!active) {
			setTimeout(() => setDescription(true), 500);
		} else setDescription(false);
	};
	const trackClick = (resourceid) => {
		let data = {
			userid: auth.userid,
			resourceid,
		};
		fetch(`https://www.api.tctcollab.com/tracking/resourceclick`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then((results) => results.json())
			.then((data) => {});
	};
	const titleStyleContainer = {
		color: color,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: bgColor,
		minHeight: "68px",
	};
	const titleStyle = {
		fontSize: "16px",
		padding: "10px",
	};
	const boxStyleContainer = {
		display: display,
		// backgroundSize:"cover",
		backgroundImage: `url(${bgImage})`,
	};
	const descriptionStyle = {
		overflowY: "auto",
		background: "rgba(220,220,220,.9)",
		// height:"100%"
	};
	useEffect(() => {
		// console.log(props);
	}, []);
	return (
		<div className="question-container">
			<div className="box-spacer">
				{description ? (
					<div
						className="box-description"
						style={descriptionStyle}
						dangerouslySetInnerHTML={{ __html: props.description }}
					>
						{/* {props.description} */}
					</div>
				) : null}
			</div>
			{props.img !== 0 && (
				<div className="question-img-container">
					<img src={props.img} alt="" />
				</div>
			)}
			<div className="question-box-question">
				<h3>{props.question}</h3>
			</div>

			<div className="question-box-answer">
				{props.type == "TF" && (
					<div key={`radio${[props.id]}`} className="answer-box">
						<Form.Check
							label={props.answer_1}
							name="Answer"
							type="radio"
							id={`a1${props.id}`}
							onClick={() => props.answered(1)}
						/>
						<Form.Check
							label={props.answer_2}
							name="Answer"
							type="radio"
							id={`a2${props.id}`}
							onClick={() => props.answered(2)}
						/>
					</div>
				)}
				{props.type == "MC" && (
					<div key={`radio${[props.id]}`} className="answer-box">
						<Form.Check
							label={props.answer_1}
							name="Answer"
							type="radio"
							id={`a1${props.id}`}
							onClick={() => props.answered(1)}
						/>
						<Form.Check
							label={props.answer_2}
							name="Answer"
							type="radio"
							id={`a2${props.id}`}
							onClick={props.answered(2)}
						/>
						<Form.Check
							label={props.answer_3}
							name="Answer"
							type="radio"
							id={`a3${props.id}`}
							onClick={props.answered(3)}
						/>
					</div>
				)}
			</div>
		</div>
	);
}
