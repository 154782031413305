import React from "react";
import Im1 from "../img/guides/Slide1.JPG";
import Im2 from "../img/guides/Slide2.JPG";
import Im3 from "../img/guides/Slide3.JPG";
import Im4 from "../img/guides/Slide4.JPG";
import Im5 from "../img/guides/Slide5.JPG";
import Im6 from "../img/guides/Slide6.JPG";
import Im7 from "../img/guides/Slide7.JPG";
import Im8 from "../img/guides/Slide8.JPG";



export default function Guide(){
    const style={
        maxWidth:"800px",
        textAlign:"center",
        margin:"auto",
        boxShadow:"5px 5px 5px grey"
    }
    const containerStyle={
        textAlign:"center",
        width:"100%"
    }

    return(
        <div className="guide-container" style={containerStyle}>
            <div style={{marginBottom:"20px"}}>
                <h3>Welcome to the PM/Awardee Onboarding System</h3>
                <p style={{textAlign:"justify",textJustify:"inter-word"}}>The <strong>PM/Awardee Onboarding</strong> is a digital tool for Office on Smoking and Health Program Services Branch (OSH/PSB) Public Health Advisors (PHAs) to use in tracking technical assistance provided to new program staff during their first year working in OSH-funded programs. It includes specific steps, timelines, information, and resources for POs to use in orienting—or onboarding—new NTCP program staff during distinct phases throughout the year. This electronic checklist helps ensure that all new NTCP program staff receive this critical assistance early to help boost their success in the future.</p> 

                <p style={{textAlign:"justify",textJustify:"inter-word"}}><strong>Note:</strong> <em>New NTCP PM/Awardee onboardees will be using the "Toolkit" located on the <strong>Tobacco Control Essentials</strong> tab to follow along in the onboarding process.  There, they will find important core tobacco control resources they will be able to use regularly as they become acclimated to commercial tobacco control, in general.</em></p>
                <p>To view your staff, select an option on the left. (view image below)</p>
                <img src={Im1} style={style} rel="Image 1"/>
                <p>You can search for organizations and staff by using the search box as well. (view image below)</p>
                <img src={Im2} style={style} rel="Image 2"/>
                <p>Each of your staff members will appear in a card to the right of the selection menu, sorted by name. (view image below) In this card, you will see your staff member's name, title, onboarding status, percentage of onboarding completed, and organization. These cards are also color coded based on your progress in onboarding each staff member. You can toggle their onboarding status but clicking the toggle below their title.</p>
                <img src={Im3} style={style} rel="Image 3"/>
                <p>Click on the progress bar to access your staff member's individualized onboarding checklist. (view image below)</p>
                <img src={Im4} style={style} rel="Image 4"/>
                <p>Select an option in the left menu in order to see the tasks for each phase. (view image below)</p>
                <img src={Im6} style={style} rel="Image 6"/>
                <p>Each task item is listed on the right. (view image below) Click on each tasks name to expand it and see a description of what your required to review with your staff member. If you see a green paperclip on the left of the task. You can click it to access an attachment that your staff member will have access to in the toolkit on the <strong>Tobacco Control Essentials Tab</strong>. When you have completed the task, click the checkbox on the right to mark it as completed in the system. These checkboxes are important, as they will be the only way to update the progress of your staff onboarding. These updates will appear in the taskbar upon your next visit to the PM/Awardee Onboarding.</p>
                <img src={Im7} style={style} rel="Image 7"/>
                <p>At the top of the page you will notice your staff member's name, organization and phased status. Here, you can click to mark an entire phase complete by toggling the switch underneath each phase's progress bar. (view image below) As with the checkboxes, this update to the status will appear upon your next visit to the PM/Awardee Onboarding. </p>
                <img src={Im8} style={style} rel="Image 8"/>
            </div>
        </div>
    )
}