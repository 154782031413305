import React from "react";
import {
	Col,
	Container,
	Row,
	Table,
	Button,
	InputGroup,
	FormControl,
	Image,
	Form,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
export default function DataTable(props) {
	const location = useLocation();
	const [info, setInfo] = useState([]);
	const [headers, setHeaders] = useState([]);
	const [stateClicks, setStateClicks] = useState([]);
	const [stateRegistered, setStateRegistered] = useState([]);
	const [statePmsRegistered, setStatePmsRegistered] = useState([]);

	const buttonClick = (b) => {
		if (b == "s") {
		} else if (b == "g") {
		} else if (b == "n") {
			///filter fArray into nArray dont forget ....
		} else if (b == "o") {
			///filter fArray into nArray dont forget ....
		} else {
			///fArray into nArray dont forget ....
		}
	};
	useEffect(() => {
		setInfo(props.data);
		// console.log(props.data);
		setHeaders(props.headers);
	}, []);
	useEffect(() => {
		setInfo(props.data);
		setHeaders(props.headers);
	}, [props]);
	return (
		<Table>
			<thead>
				<tr>
					{headers.map((d) => {
						return <th key={d}>{d}</th>;
						// console.log(d);
					})}
				</tr>
			</thead>
			<tbody>
				{info.map((d) => {
					return (
						<tr>
							{Object.entries(d).map(([k, v]) => {
								return <td>{v}</td>;
								// console.log(v);
							})}
						</tr>
					);
				})}
			</tbody>
		</Table>
	);
}
