import React from "react";
import { Button } from 'react-bootstrap';
import { Form,Container,Row,Col } from 'react-bootstrap';
import {useNavigate, useParams} from "react-router-dom";
import { useState } from "react";
export default function PasswordReset(props){
    let params =useParams();
    const [password,setPassword]=useState("");
    const [confirmPassword,setConfirmPassword]=useState("");
    const [message,setMessage]=useState("");
    const style={
        maxWidth:"800px",
        textAlign:"center",
        margin:"auto",
        boxShadow:"5px 5px 5px grey"
    }
    const handlePassword=(event)=>{
        setPassword(event.target.value)
    }
    const handleConfirmPassword=(event)=>{
        setConfirmPassword(event.target.value)
    }
    const handleSubmit=()=>{
        if(password==confirmPassword && password!==""){
            let data={
                password,
                email:params.email,
                code:params.code
            }
            fetch(`https://www.api.tctcollab.com/user/passwordreset`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            }).then((data)=>data.json())
            .then((data)=>{
                if(data.message=="success"){
                    setMessage("Your password was successully changed. Please go ahead and try to login with your new credentials.")
                }
                else{
                    setMessage("Your password could not be changed, please revisit the link from your password reset email and try again.")
                }
            })
        }
    }
    return(
        <div className="steps-container">
            <div  style={{marginBottom:"20px"}}>
                <Container><Row><Col>
            <h2 style={{color:"blue"}}>{message!=="" && message}</h2>    
            <h3>Please Reset your password for TCTC below:</h3>
            <Form.Group className="mb-3" controlId="formPassword">
            <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Password" onChange={handlePassword} value={password}/>
            </Form.Group>
            <div>
                <Form.Group className="mb-3" controlId="formConfirmPassword">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control type="password" placeholder="Confirm Password" onChange={handleConfirmPassword} value={confirmPassword}/>
                </Form.Group>
            </div>
            <Button variant="success" onClick={()=>handleSubmit()}>
            Submit
          </Button>
            </Col></Row></Container>
            </div>
        </div>
    )
}