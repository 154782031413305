import React, { useEffect, useState } from "react";
import Menu from "./menu";
import { Container, Row, Col, Form, Table } from "react-bootstrap";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import AuthContext from "../context/authprovider";
import { useContext, useCallback } from "react";
import { useLocation } from "react-router-dom";
export default function OnboardingSurvey() {
	///PULL TL ID FROM CONTEXT
	const { auth } = useContext(AuthContext);
	const [userid, setUserid] = useState(auth.userid);
	const SURVEY_ID = 1;
	const surveyJson = {
		elements: [
			{
				type: "matrix",
				name: "matrix",
				title:
					"Please indicate if you agree or disagree with the following statements",
				columns: [
					{
						value: 5,
						text: "Strongly agree",
					},
					{
						value: 4,
						text: "Agree",
					},
					{
						value: 3,
						text: "Neutral",
					},
					{
						value: 2,
						text: "Disagree",
					},
					{
						value: 1,
						text: "Strongly disagree",
					},
				],
				rows: [
					{
						value: "1",
						text: "The PHA provided me with an overview of the NTCP, OSH and how my state work plan goals support the overarching NTCP goals. ",
					},
					{
						value: "2",
						text: "The PHA discussed with me the Notice of Funding Opportunity and how it supports the achievement of the four NTCP goals.",
					},
					{
						value: "3",
						text: "The PHA discussed with me the key staff required for the tobacco control program to support work plan goals, if applicable.",
					},
					{
						value: "4",
						text: "The PHA answered any questions I had regarding the NTCP.",
					},
				],
				alternateRows: true,
				isAllRowRequired: true,
			},
			{
				type: "radiogroup",
				name: "5",
				title:
					"I completed the Onboarding process within ___ of my start in the tobacco control program:",
				choices: ["1-6 Months", "7-12 Months", "Over 12 Months"],
			},
			{
				type: "matrix",
				name: "matrix",
				title:
					"Please indicate if you agree or disagree with the following statements",
				columns: [
					{
						value: 5,
						text: "Strongly agree",
					},
					{
						value: 4,
						text: "Agree",
					},
					{
						value: 3,
						text: "Neutral",
					},
					{
						value: 2,
						text: "Disagree",
					},
					{
						value: 1,
						text: "Strongly disagree",
					},
				],
				rows: [
					{
						value: "6",
						text: "The timeliness of the AWARDEE ONBOARDING PROGRAM met my needs ",
					},
					{
						value: "7",
						text: "The content of the AWARDEE ONBOARDING PROGRAM sufficiently introduced me to the basic concepts of commercial tobacco control.",
					},
					{
						value: "8",
						text: "The content of the AWARDEE ONBOARDING PROGRAM sufficiently introduced me to my role in the program.",
					},
					{
						value: "9",
						text: "My understanding of the NTCP and OSH has improved or increased as a result of the onboarding program.",
					},
					{
						value: "10",
						text: "The Onboarding Toolkit was easy to access.",
					},
					{
						value: "11",
						text: "The Onboarding Toolkit was useful during the Onboarding Process.",
					},
					{
						value: "12",
						text: "Based on my experience with the AWARDEE ONBOARDING PROGRAM, I found my participation in the program beneficial to getting started in the NTCP. ",
					},
				],
				alternateRows: true,
				isAllRowRequired: true,
			},
			{
				name: "13",
				title:
					"What is different now for you (or your program) because of your receipt of AWARDEE ONBOARDING? ",
				type: "text",
			},
			{
				name: "14",
				title:
					"What aspects of this AWARDEE ONBOARDING PROGRAM were most beneficial to you and your program goals?",
				type: "text",
			},
			{
				name: "15",
				title: "Other feedback you would like to provide: ",
				type: "text",
			},
		],
	};

	const survey = new Model(surveyJson);

	const saveSurveyResults = (sender, options) => {
		options.showSaveInProgress();
		console.log(sender.data);
		fetch("https://www.api.tctcollab.com/survey/store", {
			method: "POST",
			headers: {
				"Content-Type": "application/json;charset=UTF-8",
			},
			body: JSON.stringify({
				userid: auth.userid,
				pollid: SURVEY_ID,
				results: sender.data,
			}),
		})
			.then((response) => {
				// if (response) {
				// 	// Handle success
				// } else {
				// 	// Handle error
				// }
				console.log(response);
			})
			.catch((error) => {
				// Handle error
			});
	};
	// const surveyComplete = useCallback((sender) => {
	// 	saveSurveyResults("https://your-web-service.com/" + SURVEY_ID, sender.data);
	// }, []);
	survey.onComplete.add(saveSurveyResults);
	survey.onComplete.add((data) => console.log(data));
	return <Survey model={survey} />;
}
