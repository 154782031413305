import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Table } from "react-bootstrap";
export default function Results(props) {
	const style = {
		maxWidth: "800px",
		textAlign: "center",
		margin: "auto",
		boxShadow: "5px 5px 5px grey",
	};
	const [registrants, setRegistrants] = useState([]);
	useEffect(() => {
		fetch(`https://www.api.tctcollab.com/report/event/registration`, {
			headers: {
				"Cache-Control": "no-cache",
			},
		})
			.then((res) => res.json())
			.then((data) => {
				// console.log(data);
				setRegistrants(data);
			}, []);
	});
	return (
		<div className="results-container">
			<Container>
				<Row>
					<Col>
						<h4>LSS Registrants</h4>
						<Table>
							<thead>
								<tr>
									<th>First Name</th>
									<th>Last Name</th>
									<th>Title</th>
									<th>Role</th>
									<th>Organization</th>
									<th>Cohort</th>
								</tr>
							</thead>
							<tbody>
								{registrants.map((registrant) => {
									return (
										<tr>
											<td>{registrant.fname}</td>
											<td>{registrant.lname}</td>
											<td>{registrant.title}</td>
											<td>{registrant.Role}</td>
											<td>{registrant.Organization}</td>
											<td>{registrant.Cohort}</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</Col>
				</Row>
			</Container>
		</div>
	);
}
