import React from "react";
import * as d3 from "d3";
// import { useEffect } from "react";
// import * as d3 from "https://cdn.jsdelivr.net/npm/d3@7/+esm";

// import * as d3scale from "d3-scale";
// import { axisLeft, axisBottom } from "d3-axis";

class BarChart extends React.Component {
	constructor(props) {
		super(props);
		this.state = { data: this.props.data, title: this.props.title };
		// useEffect(() => {}, [this.props]);
	}

	// drawChart(data) {
	// 	// const data = this.state.data;
	// 	const svg = d3
	// 		.select("#dashboard-container")
	// 		.append("div")
	// 		.attr("id", this.props.id)
	// 		.attr("class", "d3-chart-container")
	// 		.append("svg")
	// 		.attr("width", "calc(100% - 20px)")
	// 		.attr("height", "calc(100% - 20px)");

	// 	svg
	// 		.selectAll("rect")
	// 		.data(data)
	// 		.enter()
	// 		.append("rect")
	// 		.attr("x", (d, i) => this.xStart(d, i, data))
	// 		.attr("y", (d, i) => 300 - d.count - 20)
	// 		.attr("width", (d, i) => this.barWidth(d, i, data))
	// 		.attr("height", (d, i) => d.count * 10)
	// 		.attr("fill", "green")
	// 		.text((d) => d);

	// 	svg
	// 		.selectAll("text")
	// 		.data(data)
	// 		.enter()
	// 		.append("text")
	// 		.text((d) => d.competency)
	// 		.attr("x", (d, i) => this.xStart(d, i, data))
	// 		.attr("y", (d, i) => 300 - d.count - 23);
	// 	// .style("text-anchor", "end")
	// 	// .attr("transform", "rotate(-90)");

	// 	const xLabels = svg
	// 		.selectAll(".xLabel")
	// 		.data(data)
	// 		.enter()
	// 		.append("text")
	// 		.text((d) => d.competency)
	// 		.attr("x", (d, i) => this.xStart(d, i, data))
	// 		.attr("y", 280)
	// 		.style("text-anchor", "end");
	// 	// .attr("transform", "rotate(-90)");
	// }

	xStart = (d, i, data) => {
		// return i * 70;
		return (i * 480) / data.length;
		if (i != 0) {
			return i * 70 + 40;
		} else {
			return 40;
		}
	};
	barWidth = (d, i, data) => {
		return 480 / data.length - 10;
	};

	drawChart2(data, title, header) {
		var chart = d3
			.select("#dashboard-container")
			.append("div")
			.attr("id", this.props.id)
			.attr("class", "d3-chart-container");
		var svg = chart.append("svg").attr("width", "100%").attr("height", "100%"),
			margin = 0;
		// width = svg.attr("width") - margin,
		// height = svg.attr("height") - margin;
		const width = document.getElementById(this.props.id).offsetWidth - 100;
		const height = document.getElementById(this.props.id).offsetHeight - 300;
		var hoverDiv = chart
			// .select(".d3-chart-container")
			.append("div")
			.attr("class", "tooltip-bar")
			.style("opacity", 0);
		svg
			.append("text")
			.attr("transform", "translate(70,0)")
			.attr("x", width / 2)
			.attr("y", 50)
			.attr("font-size", "20px")
			.attr("text-anchor", "middle")
			.text(title);

		var xScale = d3.scaleBand().range([0, width]).paddingInner(0.5),
			yScale = d3.scaleLinear().range([height, 0]);

		var g = svg
			.append("g")
			.attr("transform", "translate(" + 70 + "," + 70 + ")");

		xScale.domain(
			data.map(function (d) {
				return d.header;
			})
		);
		yScale.domain([
			0,
			d3.max(data, function (d) {
				return d.count;
			}),
		]);
		var color = d3.schemeCategory10;

		const xaxislabels = g
			.append("g")
			.attr("transform", "translate(0," + height + ")")
			.call(d3.axisBottom(xScale));

		xaxislabels
			.selectAll("text")
			.attr("text-anchor", "end")
			.attr("dx", "-1em")
			.attr("dy", "-0.5em")
			.attr("transform", "rotate(-80)");

		xaxislabels
			.append("text")
			.attr("y", height - 230)
			.attr("x", width / 2)
			.attr("text-anchor", "middle")
			.attr("stroke", "black")
			.attr("font-size", "15px")
			.text(header[0]);

		g.append("g")
			.call(d3.axisLeft(yScale))
			.append("text")
			.attr("transform", "rotate(-90)")
			.attr("x", -height / 2)
			.attr("y", 25)
			.attr("dy", "-5.1em")
			.attr("text-anchor", "middle")
			.attr("stroke", "black")
			.attr("font-size", "15px")
			.text(header[1]);

		g.selectAll(".bar")
			.data(data)
			.enter()
			.append("rect")
			.attr("class", "bar")
			.attr("x", function (d) {
				return xScale(d.header);
			})
			.attr("y", function (d) {
				return yScale(d.count);
			})
			.attr("width", xScale.bandwidth())
			.attr("height", function (d) {
				return height - yScale(d.count);
			})
			.attr("fill", function (d, i) {
				return color[i];
			})
			.on("mouseover", function (d) {
				hoverDiv
					.html(
						"<div> <strong>" +
							header[0] +
							"</strong> " +
							d3.select(this).datum().header +
							"</div><div> <strong>" +
							header[1] +
							"</strong> " +
							d3.select(this).datum().count +
							"</div>"
					)
					.style("left", d3.pointer(d, ".bar")[0] + 10 + "px")
					.style("top", d3.pointer(d, ".bar")[1] - 15 + "px");
				d3.select(this).transition().duration("50").attr("opacity", ".85");
				hoverDiv.transition().duration(50).style("opacity", 1);
				// console.log(d3.select(this).datum().competency);

				// console.log(d3.pointer(this));
			})
			.on("mouseout", function (d, i) {
				d3.select(this).transition().duration("50").attr("opacity", "1");

				hoverDiv.transition().duration("50").style("opacity", 0);
			});
		// });
	}

	componentDidMount() {
		this.drawChart2(this.props.data, this.props.title, this.props.header);
	}
	componentDidUpdate(prevProps) {
		const element = document.getElementById(this.props.id);
		element.remove();
		this.drawChart2(this.props.data, this.props.title, this.props.header);
	}
	componentWillUnmount() {
		const element = document.getElementById(this.props.id);
		element.remove();
	}
	render() {
		return <div id={"#" + this.props.id}></div>;
	}
}

export default BarChart;
