import React, { useEffect, useState } from "react";
import "../styles/mybutton.css";

export default function MyButton(props) {
	const [bgColor, setBgColor] = useState("blue");
	const buttonStyle = {
		boxSizing: "border-box",
		boxShadow: "1px 1px 5px grey",
		backgroundColor: bgColor,
		color: "white",
		padding: "5px",
		margin: "5px",
		fontWeight: "bold",
	};
	useEffect(() => {
		let tBgColor;
		switch (props.variant) {
			case "orange":
				setBgColor("rgb(255,150,34)");
				break;
			case "blue":
				setBgColor("rgb(4,63,212)");
				break;
			case "red":
				setBgColor("rgb(117, 8, 0)");
				break;
			case "green":
				setBgColor("rgb(57,127,12)");
				break;
			case "yellow":
				break;
			case "purple":
				setBgColor("rgb(139,20,213)");
				break;
			case "grey":
				setBgColor("rgb(137, 141, 163)");
				break;
			case "lightblue":
				setBgColor("#0ADAFF");
				break;
		}
	}, [props.variant]);
	return (
		<div
			className="my-button"
			style={buttonStyle}
			onClick={() => props.onClick()}
		>
			{props.children}
		</div>
	);
}
