import React, { useEffect, useState } from "react";

export default function Footer(){
    const [position,setPosition] = useState("static");
    const style={
        position:position,
        bottom:"0",
        height:"100px",
        width:"100%",
        backgroundColor:"rgba(0,200,200,.8)",
        color:"darkblue", 
        fontSize:"18px",
        padding:"30px"
        // fontWeight:"bold"

    }
    // useEffect(()=>{
    //     if(window.innerHeight==document.documentElement.scrollHeight){
    //         setPosition("absolute");
    //     }
    //     console.log(window.innerHeight)
    //     console.log(document.documentElement.scrollHeight)
    // },[document.documentElement.innerHeight])
    return(
        <div className="footer" style={style}>
Property of IRC, LLC. Copyright ©️ 2022. All rights reserved. www.tctcollab.com
Authorized use only.</div>
    )

}