import React, { useEffect, useState } from "react";
import Menu from "./menu";
import { Container, Row, Col, Form, Table } from "react-bootstrap";
import OnboardingBox from "./onboardingbox";
import MyButton from "./mybutton";
import Steps from "./steps";
import Guide from "./guide";
import AuthContext from "../context/authprovider";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
export default function TlApproval() {
	///PULL TL ID FROM CONTEXT
	const { auth } = useContext(AuthContext);
	const [tlid, setTlid] = useState(auth.userid);
	const [approvalList, setApprovalList] = useState([]);

	useEffect(() => {
		// setTlid(3);
		// fetch(`http://localhost:3001/pmonboarding/list/toggle/tl/${tlid}`);
		fetch(`https://www.api.tctcollab.com/pmonboarding/list/toggle/tl/3`)
			.then((data) => data.json())
			.then((data) => {
				// console.log(data);
				setApprovalList(data);
				// console.log(data);
			});
	}, []);
	const changeToggle = (id) => {
		let data = { toggle: "0", id };
		fetch(`https://www.api.tctcollab.com/pmonboarding/toggle/tl/user`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		});
	};
	return (
		<Table>
			<thead>
				<tr>
					<th>PHA</th>
					<th>Program Manager</th>
					<th>Organization</th>
					<th>Reason</th>
					<th>Approve?</th>
				</tr>
			</thead>
			<tbody>
				{approvalList.map((pm) => {
					return (
						<tr>
							{/* {console.log(pm.fname)} */}
							<td>{pm.pha_fname + " " + pm.pha_lname}</td>
							<td>{pm.fname + " " + pm.lname}</td>
							<td>{pm.descr}</td>
							<td>{pm.reason}</td>
							<td>
								<Form.Check
									type="switch"
									// label="Onboard Process"
									// checked={onboarded}
									onClick={() => changeToggle(pm.userid)}
								/>
							</td>
						</tr>
					);
				})}
			</tbody>
		</Table>
	);
}
