import React from "react";
import Im1 from "../img/guides/tce//Slide1.JPG";
import Im2 from "../img/guides/tce//Slide2.JPG";
import Im3 from "../img/guides/tce//Slide3.JPG";
import Im4 from "../img/guides/tce//Slide4.JPG";
import Im5 from "../img/guides/tce//Slide5.JPG";

export default function Steps() {
	const style = {
		maxWidth: "800px",
		textAlign: "center",
		margin: "auto",
		boxShadow: "5px 5px 5px grey",
	};

	return (
		<div className="steps-container">
			<div style={{ marginBottom: "20px" }}>
				<h3>Welcome to the Tobacco Control Essentials</h3>
				<p style={{ textAlign: "justify", textJustify: "inter-word" }}>
					The <strong>Tobacco Control Essentials</strong> provides access to
					knowledge-building resources to benefit all National Tobacco Control
					Program (NTCP) members in support of your efforts to reduce the
					personal and societal burdens of tobacco-related disease, disability,
					and death related to commercial tobacco use in the U.S. These
					resources support the overarching NTCP goals to: Prevent initiation
					among youth and young adults; Promote quitting among adults and youth;
					Eliminate exposure to secondhand smoke; and Advance health equity by
					identifying and eliminating commercial tobacco produce-related
					inequities and disparities.
				</p>
				<p style={{ textAlign: "justify", textJustify: "inter-word" }}>
					This tool has been designed to increase awareness of and access to the
					knowledge and tools needed to educate the NTCP about tobacco
					prevention and control. Resources, herein, provide information on
					recommended, evidence-based approaches primarily for statewide
					comprehensive tobacco control programs. The five components outlined
					in CDC's 2014 Best Practices for Comprehensive Tobacco Control
					Programs serve as the core foundation for the content in this tool.
				</p>

				<p style={{ textAlign: "justify", textJustify: "inter-word" }}>
					<strong>Note:</strong>{" "}
					<em>
						Resources in this tool can be sorted by commercial tobacco control
						topic or tobacco control competency category.
					</em>
				</p>

				<p>
					To view our resources, select an option on the left. (view image
					below)
				</p>
				<img src={Im1} style={style} rel="Image 1" />
				<p>
					Once you have selected an option, our resources and a more detailed
					filter menu will appear on the right. At the top of this menu, you can
					search for resources you are looking for. (view image below)
				</p>
				<img src={Im2} style={style} rel="Image 2" />
				<p>
					Underneath the search bar, you can click on any of the check boxes
					listed to filter down to a certain type of resource.
				</p>
				<img src={Im3} style={style} rel="Image 3" />
				<p>
					Underneath the "Type" filters, you will be able to filter the
					resources by competency. (view image below)
				</p>
				<img src={Im4} style={style} rel="Image 4" />
				<p>
					Underneath this filter menu, click on the colored title of the
					resource you would like to view in order to open it in a new tab.
					(view image below)
				</p>
				<img src={Im5} style={style} rel="Image 5" />
			</div>
		</div>
	);
}
