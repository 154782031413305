import React from "react";
import {
    Col,
    Container,
    Row,
    Table,
    Button,
    InputGroup,
    FormControl,
      Image,
    Form
  } from "react-bootstrap";
import Connect from '../img/connect.jpeg';
import Learn from '../img/learn.jpeg';
import Share from '../img/share.jpeg';
import Lead from '../img/lead.jpeg';
import Collab from '../img/collab.jpeg';

export default function Home(){
    const homeStyle={
        backgroundColor:"rgb(15,58,214)",
        marginTop:"-30px",
        padding:"20px",
        paddingBottom:"40px"
    }
    const headerStyle={
        color:"rgb(0,255,213)",
        fontWeight:"bold",
        fontSize:"30px",

    }
    const pStyle={
        color:"white",
        fontWeigh:"bold",
        fontSize:"20px"
    }
    const rowStyle={
        marginBottom:"100px",
        marginTop:"100px"
    }
    return(
        <div className="home-container" style={homeStyle}>
            <Container>
                <Row>
                    <Col>
                        <h3  style={headerStyle}>Connect. Collaborate. Learn. Share. Lead. Succeed.</h3>
                        <p  style={pStyle}>
                            With a mission to advance proven practices through innovative training opportunities and tailored technical assistance to members of the National Tobacco Control Program (NTCP), the Tobacco Control Training Collaborative (TCTC) strives to increase NTCP capacity to implement evidence-based tobacco prevention and control strategies.
                        </p>
                    </Col>
                </Row>
                <Row style={rowStyle}>
                    <Col sm="6">
                        <div className="img-container" style={{overflow:"hidden",width:"300px",height:"300px",margin:"auto", borderRadius:"150px"}}>
                            <img src={Connect} alt="connect" style={{height:"100%",margin:"auto"}}/>
                        </div>
                    </Col>
                    <Col sm="6" style={{display:"flex"}}>
                        <div className="m-auto">
                            <p style={headerStyle}>Connect</p>
                            <p className="m-auto" style={pStyle}>Secure, web-based access at www.tctcollab.com ensures NTCP stakeholders have access to tobacco control resources from anywhere at any time.</p>
                        </div>
                    </Col>
                </Row>
                <Row style={rowStyle}>
                    
                    <Col sm="6" style={{display:"flex"}}>
                        <div className="m-auto">
                            <p style={headerStyle}>Collaborate</p>
                            <p className="m-auto" style={pStyle}>Collaboration with fellow NTCP stakeholders via up-to-date contact information through the Key Personnel Directory.</p>
                        </div>
                    </Col>
                    <Col sm="6">
                        <div className="img-container" style={{overflow:"hidden",width:"300px",height:"300px",margin:"auto", borderRadius:"150px"}}>
                            <img src={Collab} alt="Collab" style={{height:"100%",margin:"auto"}}/>
                        </div>
                    </Col>
                </Row>
                <Row style={rowStyle}>
                    <Col sm="6">
                        <div className="img-container" style={{overflow:"hidden",width:"300px",height:"300px",margin:"auto", borderRadius:"150px"}}>
                            <img src={Learn} alt="Learn" style={{height:"100%",margin:"auto"}}/>
                        </div>
                    </Col>
                    <Col sm="6" style={{display:"flex"}}>
                        <div className="m-auto">
                            <p style={headerStyle}>Learn</p>
                            <p className="m-auto" style={pStyle}>Ability to expand current tobacco knowledge through the Tobacco Essentials training course and resource listing.</p>
                        </div>
                    </Col>
                </Row>
                <Row style={rowStyle}>                    
                    <Col sm="6" style={{display:"flex"}}>
                        <div className="m-auto">
                            <p style={headerStyle}>Share</p>
                            <p className="m-auto" style={pStyle}>Enhanced ability to share information with other NTCP stakeholders through OSH/PSB-hosted Webinars and Communities-of-Practice (CoPs).</p>
                        </div>
                    </Col>
                    <Col sm="6">
                        <div className="img-container" style={{overflow:"hidden",width:"300px",height:"300px",margin:"auto", borderRadius:"150px"}}>
                            <img src={Share} alt="Share" style={{height:"100%",margin:"auto"}}/>
                        </div>
                    </Col>
                </Row>
                <Row style={rowStyle}>
                    <Col sm="6">
                        <div className="img-container" style={{overflow:"hidden",width:"300px",height:"300px",margin:"auto", borderRadius:"150px"}}>
                            <img src={Lead} alt="Lead" style={{height:"100%",margin:"auto"}}/>
                        </div>
                    </Col>
                    <Col sm="6" style={{display:"flex"}}>
                        <div className="m-auto">
                            <p style={headerStyle}>Lead</p>
                            <p className="m-auto" style={pStyle}>Participation in the Leadership & Sustainability School serves as a dedicated means to help develop and sharpen tobacco control leadership skills.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}