import React, { useEffect, useState } from "react";
import "../styles/pmo.css";
import { useNavigate, Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Avatar from "../img/avatar.png";
import { useLocation } from "react-router-dom";
import MyButton from "./mybutton";
export default function OnboardingBox(props) {
	const location = useLocation();
	const [barColor, setBarColor] = useState("");
	const [bgColor, setBgColor] = useState("");
	const [barPercentage, setBarPercentage] = useState(0);
	const [pColor, setPColor] = useState("black");
	const [onboarded, setOnboarded] = useState(true);
	const [textColor, setTextColor] = useState("black");
	const [reason, setReason] = useState("");
	const [showReason, setShowReason] = useState("none");
	const boxStyle = {
		width: "300px",
		height: "350px",

		border: "1px solid black",
		boxSizing: "border-box",
		boxShadow: "2px 2px 5px grey",
		transition: "background-color .5s",
		margin: "10px",
		position: "relative",
		overflow: "hidden",
	};
	const blurStyle = {
		backgroundColor: "rgba(200,200,200,.8)",

		width: "100%",
		height: "100%",
		display: "flex",
		flexFlow: "column",
		justifyContent: "spaceBetween",
		boxSizing: "border-box",
	};
	const contentContainerStyle = {
		width: "290px",
		height: "340px",
		// display:"flex",
		flexFlow: "column",
		justifyContent: "spaceBetween",
		background: { Avatar },
		position: "absolute",
		top: "5px",
		left: "5px",
		zIndex: "4",
		backgroundColor: bgColor,

		boxSizing: "border-box",
	};
	const contentContainerBGStyle = {
		width: "290px",
		height: "340px",
		display: "flex",
		flexFlow: "column",
		// justifyContent:"spaceBetween",
		background: `url(${Avatar}) center no-repeat`,
		backgroundSize: "90%",
		position: "absolute",
		// top:"5px",
		// left:"5px",
		// zIndex:"4",
		// backgroundColor:bgColor,

		// boxSizing:"border-box"
	};
	const reasonStyle = {
		position: "absolute",
		width: "100%",
		height: "100%",
		zIndex: "1000",
		top: "0",
		left: "0",
		backgroundColor: "white",
		padding: "10px",
		display: showReason,
	};
	const gNameStyle = {
		fontWeight: "bold",
		color: textColor,
		width: "100%",
		paddingTop: "10px",
		fontSize: "30px",
		zIndex: "200",
	};
	const gTextStyle = {
		fontWeight: "bold",
		color: textColor,
		width: "100%",
		paddingBottom: "10px",
	};
	const gToggleStyle = {
		fontWeight: "bold",
		color: textColor,
		paddingBottom: "10px",
		margin: "auto",
		display: "flex",
		width: "100%",
		justifyContent: "center",
	};
	const barContainerStyle = {
		height: "30px",
		backgroundColor: "rgb(250,250,250)",
		width: "80%",
		borderRadius: "15px",
		overflow: "hidden",
		boxShadow: "inset 0 0 5px grey",
	};
	const barStyle = {
		paddingTop: "3px",
		paddingBottom: "3px",
		minHeight: "100%",
		width: barPercentage + "%",
		backgroundColor: barColor,
		boxSizing: "border-box",
		borderRadius: "0 15px 15px 0",
		boxShadow: "inset 0 0 3px grey",
		transition: "width .5s",
	};
	const percentStyle = {
		fontWeight: "bold",
		color: pColor,
	};
	const handleReason = (event) => {
		setReason(event.target.value);
		// console.log(reason);
	};
	const handleSubmit = () => {
		if (reason.length >= 5) {
			let data = { pha: props.phaid, id: props.id, toggle: 2, reason };

			fetch("https://www.api.tctcollab.com/pmonboarding/pha/toggle", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			});
			// console.log(data);

			setShowReason("none");
		}
	};
	const changeToggle = () => {
		if (onboarded === true && props.role == "Program Manager") {
			// handleSubmit();
			setShowReason("block");
		} else if (onboarded === true) {
			let data = { toggle: "0", id: props.id };
			fetch(`https://www.api.tctcollab.com/pmonboarding/toggle/tl/user`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			});
		} else {
			let data = { toggle: "1", id: props.id };
			fetch(`https://www.api.tctcollab.com/pmonboarding/toggle/tl/user`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			});
		}
		setOnboarded(!onboarded);
	};
	const hideFunction = () => {
		fetch(`https://www.api.tctcollab.com/user/inactivate/`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ userid: props.id }),
		})
			.then((response) => response.json())
			.then((data) => {
				// console.log(data);
				props.hide(props.id);
			});
		// props.hide(props.id);
		// console.log("hide");
	};
	// const openOnboarding=()=>{
	//     let navigate= useNavigate()
	//     navigate(`${props.id}`)
	// }
	useEffect(() => {
		if (props.onboarding == null || props.onboarding == 1) {
			setOnboarded(true);
		} else {
			setOnboarded(false);
		}
		setTimeout(() => {
			setBarPercentage(props.percentage);
		}, 500);
		props.percentage < 20
			? setBarColor("red")
			: props.percentage < 80
			? setBarColor("yellow")
			: setBarColor("green");
		props.percentage < 20
			? setBgColor("red")
			: props.percentage < 80
			? setBgColor("yellow")
			: setBgColor("green");
		props.percentage >= 80 ? setPColor("white") : setPColor("black");
	}, []);
	useEffect(() => {
		setBarPercentage(props.percentage);
		props.percentage < 20
			? setBarColor("red")
			: props.percentage < 80
			? setBarColor("yellow")
			: setBarColor("green");
		props.percentage < 20
			? setBgColor("red")
			: props.percentage < 80
			? setBgColor("yellow")
			: setBgColor("green");
		props.percentage >= 80 ? setPColor("white") : setPColor("black");
		// console.log(props.percentage)
	}, [props.percentage]);
	return (
		<div className="onboarding-box" style={boxStyle}>
			{props.new && onboarded ? (
				barPercentage < 100 ? (
					<div className="new"></div>
				) : null
			) : null}
			<div className="border"></div>
			<div className="content-container" style={contentContainerStyle}>
				<div style={contentContainerBGStyle}>
					<div className="blurLayer" style={blurStyle}>
						<div>
							<div className="grantee-name" style={gNameStyle}>
								{props.name}
							</div>
							<div className="title" style={gTextStyle}>
								{props.role}
							</div>
							<div className="toggle-switch" style={gToggleStyle}>
								<Form.Check
									type="switch"
									label="Onboard Process"
									checked={onboarded}
									onClick={changeToggle}
								/>
							</div>
							{props.onboarding == 2 && (
								<div style={{ margin: "0" }}>
									<span style={{ color: "red" }}>
										<strong>
											<em>Pending Removal</em>
										</strong>
									</span>
								</div>
							)}
						</div>
						<Link
							to={`${props.id}`}
							className="bar-container m-auto"
							style={barContainerStyle}
						>
							<div className="bar" style={barStyle}>
								<div className="percent" style={percentStyle}>
									{barPercentage + "%"}
								</div>
							</div>
						</Link>
						<div className="grantee-name" style={gTextStyle}>
							{props.org}
						</div>
						<div className="inactivate-button" style={gTextStyle}>
							<MyButton onClick={() => hideFunction()} variant="red">
								Remove User
							</MyButton>
						</div>
					</div>
					<div className="removal-reason" style={reasonStyle}>
						<p>
							Why would you like to remove <strong>{props.name}</strong> from
							the Onboarding Process?
						</p>
						<div>
							<Form.Group className="mb-3" controlId="formBasicEmail">
								<Form.Control
									as="textarea"
									// placeholder="Enter email"
									onChange={handleReason}
									value={reason}
								/>
							</Form.Group>
							<MyButton variant="green" onClick={() => handleSubmit()}>
								Submit
							</MyButton>
							<MyButton variant="red" onClick={() => setShowReason("none")}>
								Cancel
							</MyButton>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
