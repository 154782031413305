import React from "react";
import { Button } from "react-bootstrap";
import MyButton from "./../mybutton";
import { Form, Container, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useContext, useEffect } from "react";
import LSSLogo from "../../img/LSSlogo.png";
import AuthContext from "../../context/authprovider";
export default function CoP(props) {
	let params = useParams();
	const { auth, setAuth } = useContext(AuthContext);
	const [npm, setNpm] = useState(true);
	const [yc, setYc] = useState(false);

	// const [password,setPassword]=useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [message, setMessage] = useState("");
	const style = {
		maxWidth: "800px",
		textAlign: "center",
		margin: "auto",
		boxShadow: "5px 5px 5px grey",
	};
	useEffect(() => {
		let saved = localStorage.getItem("user");
		if (saved && JSON.stringify(auth) === "{}") {
			setAuth(JSON.parse(saved));
		}
	}, []);
	useEffect(() => {}, []);
	return (
		<div className="steps-container">
			<div style={{ marginBottom: "20px" }}>
				<Container>
					<Row>
						<div
							style={{
								width: "100%",
								textAlign: "center",
								marginBottom: "30px",
							}}
						>
							<img
								style={{ width: "20%", textAlign: "center" }}
								src={LSSLogo}
							/>
						</div>
					</Row>
					<Row>
						<div className="cop-container npm">
							{/* <div className="header-text">Purpose and Objectives</div> */}
							<p style={{ marginBottom: "30px" }}>
								The 2024 Leadership & Sustainability School addresses the need
								for a resilient, well-trained commercial tobacco prevention and
								control leadership base that can address the challenges of and
								leverage opportunities in the field, thus enabling the continued
								progress to reduce and eliminate the burden of commercial
								tobacco and nicotine use and exposure.
							</p>
							<p style={{ marginBottom: "30px" }}>
								Specifically, the 2024 L&SS aims to:
								<ul>
									<li>
										Cultivate the leadership skills of rising national
										commercial tobacco control leaders in a small-group setting.
									</li>
									<li>
										Support participants' mastery of leadership skills through
										dynamic training, design, and delivery.
									</li>
									<li>
										Foster relationship-building and networking among and
										between participants, OSH staff, and current commercial
										tobacco control leaders.
									</li>
								</ul>
							</p>
							<p>
								Upon completing the 2024 Leadership & Sustainability School,
								participants will be able to:
								<ul>
									<li>
										Advance their{" "}
										<strong>knowledge, ability, and practice</strong> of five
										leadership skills (equity; systems thinking; collaborative
										leadership; communications; and policy development and
										engagement) within the context of leading commercial tobacco
										control policy, systems, and environmental change.
									</li>
									<li>
										Develop a deeper and broader understanding of{" "}
										<strong>their leadership philosophy and practice</strong>{" "}
										and how to leverage their leadership skills to benefit their
										program, partnerships, state, island, tribe, or network, and
										the greater public health community.
									</li>
									<li>
										Integrate principles of{" "}
										<strong>
											equity and racial justice into all elements of their
											leadership practice
										</strong>
										, including developing personal, institutional, and
										structural-level approaches to equity in commercial tobacco
										control.
									</li>
									<li>
										Develop <strong>long-term relationships</strong> with
										diverse and talented commercial tobacco control leaders from
										around the country.
									</li>
									<li>
										Develop their reputation, nationally, as{" "}
										<strong>
											commercial tobacco control experts and resources
										</strong>{" "}
										to the field.
									</li>
								</ul>
							</p>
						</div>
					</Row>
				</Container>
			</div>
		</div>
	);
}
