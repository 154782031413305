import React, { useEffect, useState } from "react";
import Menu from "./menu";
import { Container, Row, Col, Form } from "react-bootstrap";
// import OnboardingBox from "./onboardingbox";
import MyButton from "./mybutton";
// import Steps from './steps';
// import Guide from "./guide";
import AuthContext from "../context/authprovider";
import { useContext } from "react";
import { useLinkClickHandler, useLocation } from "react-router-dom";
// import QuestionBox from './questionbox';
export default function Upcoming() {
	const location = useLocation();
	const [question, setQuestion] = useState(-1);
	const [questionsArray, setQuestionsArray] = useState([
		{
			type: "TF",
			question:
				"This wonderful waifu, from the popular anime Konosuba, is named Megumin.",
			img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRy-rAO56gjaUr-RsvSItl-7jArqnR4taqWHg&usqp=CAU",
		},
		{ type: "TF", question: "Konosuba is the best anime of all time!", img: 0 },
		{
			type: "TF",
			question: "The masochist shown below is named Darkness.",
			img: "https://i.kym-cdn.com/entries/icons/original/000/036/537/a31feu0g3yg41.jpg",
		},
		{
			type: "TF",
			question:
				"Of the party in Konosuba, Aqua (shown above) is the most intelligent.",
			img: "https://www.siliconera.com/wp-content/uploads/2020/12/konosuba-fantastic-days-aqua.jpg",
		},
	]);
	const [guide, setGuide] = useState(true);
	const [search, setSearch] = useState("");
	const [showNew, setShowNew] = useState(false);
	const [showOnboarding, setShowOnboarding] = useState(false);
	///PULL PHA ID FROM CONTEXT
	const { auth } = useContext(AuthContext);
	const [phaid, setPhaid] = useState(auth.userid);

	const handleSearch = (event) => {
		// if(text.length==1){
		//     setSArray(vArray);
		// }
		// else if (text.lenth==0){
		//     setVArray(sArray);
		// }
		//filter vArray by text and store in vArray dont forget the ...
		setSearch(event.target.value.toLowerCase());
		console.log(search);
	};
	const clickHandler = (x) => {
		if (x == "next") {
			if (question != questionsArray.length - 1) {
				let temp = question;
				temp++;
				setQuestion(temp);
				console.log(temp);
			}
		} else {
			if (question != 0) {
				let temp = question;
				temp--;
				setQuestion(temp);
			}
		}
	};

	useEffect(() => {
		//pull all the users grantees and load them into the fArray
		// fetch(`https://www.api.tctcollab.com/pmonboarding/pha/${phaid}`,{headers: {
		//     'Cache-Control': 'no-cache'
		//   }})
		// .then((response)=>response.json())
		// .then((data)=>{setFArray([...data]);setVArray([...data]);
		//     // console.log(data)
		// })
	}, [location]);

	return (
		<Container>
			{/* <Row>
				<Col xl="3">
					<div className="upcoming-date-container">
						<div className="type">Videoconference</div>
						<div className="date">December 6, 2022</div>
						<div className="time">3 PM - 4 PM ET</div>
						<div className="series">2022-2023 Series (1)</div>
					</div>
					<div className="button-container">
						<MyButton
							variant="purple"
							onClick={() =>
								window.open(
									`https://www.tctcollab.com/assets/pdf/TCTC%202022-2023%20Videoconference%20Series_FNL1.pdf`,
									"_blank"
								)
							}
						>
							Series Overview
						</MyButton>
						
					</div>
				</Col>
				<Col xl="9">
					<div className="upcoming-info-container">
						<div className="title">
							Leveraging the Power of Youth Movements in Commercial Tobacco
							Control
						</div>

						<div className="objectives">
							<div className="header">Learning Objectives</div>
							<p>
								By the close of this videoconference, participants will be able
								to:
								<ul>
									<li>
										Articulate how youth movements can be leveraged in
										implementing strategic plans for a comprehensive commercial
										tobacco control program, including their role in promoting
										health equity
									</li>
									<li>
										Identify strategies and resources for building a statewide
										youth movement for commercial tobacco control efforts that
										are linked with statewide program goals
									</li>
									<li>
										Share promising practices in engaging youth in a statewide
										movement
									</li>
								</ul>
							</p>
						</div>
						<div className="speakers">
							<div className="header">Speakers</div>
							<p>
								<strong>Emily Carlson</strong> Office Chief, Tobacco Prevention
								and Cessation, Arizona
								<br />
								<strong>Leo Olu-Williams</strong> National Tobacco Control
								Program Manager, Arizona
								<br />
								<strong>Yvette Coles</strong> Youth Program Manager, Tobacco
								Prevention and Cessation, Arizona
								<br />
							</p>
						</div>
						<div className="target">
							<div className="header">Target Audience</div>
							<p>
								<ul>
									<li>Program Managers</li>
									<li>Youth Engagement Coordinators</li>
								</ul>
							</p>
						</div>
						<div className="level">
							<div className="header">Instruction Level</div>
							<p>
								<ul>
									<li>Application</li>
								</ul>
							</p>
						</div>
					</div>
				</Col>
			</Row>
			<hr />
			<Row>
				<Col xl="3">
					<div className="upcoming-date-container">
						<div className="type">Videoconference</div>
						<div className="date">January 11, 2023</div>
						<div className="time">3 PM - 4 PM ET</div>
						<div className="series">2022-2023 Series (2)</div>
					</div>
					<div className="button-container">
						<MyButton
							variant="purple"
							onClick={() =>
								window.open(
									`https://www.tctcollab.com/assets/pdf/TCTC%202022-2023%20Videoconference%20Series_FNL1.pdf`,
									"_blank"
								)
							}
						>
							Series Overview
						</MyButton>
						<MyButton
							onClick={() =>
								window.open(
									`https://us02web.zoom.us/meeting/register/tZUtf--rrDMjG9MRQmQI6Ql3p8LEHRN2MdEU`,
									"_blank"
								)
							}
						>
							Register Here!
						</MyButton>
						
					</div>
				</Col>
				<Col xl="9">
					<div className="upcoming-info-container">
						<div className="title">
							Building Strengths-Based and Trusting Relationships with Local
							Lead Agencies
						</div>

						<div className="objectives">
							<div className="header">Learning Objectives</div>
							<p>
								By the close of this videoconference, participants will be able
								to:
								<ul>
									<li>
										Understand one state's experience in developing and
										strengthening the relationship between the state commercial
										tobacco control program and the local lead agency supporting
										their commercial tobacco control-related disparities efforts
									</li>
									<li>
										Utilize three tools to support an equity approach to
										partnering with community-based organizations
									</li>
								</ul>
							</p>
						</div>
						<div className="speakers">
							<div className="header">Speakers</div>
							<p>
								<strong>Julia Hitchingham</strong> Tobacco Section Manager,
								Michigan Tobacco Control Program
								<br />
								<strong>Minou Jones</strong> Founder and CEO, Making it Count
								Community Development Corporation
								<br />
							</p>
						</div>
						<div className="target">
							<div className="header">Target Audience</div>
							<p>
								<ul>
									<li>Program Managers</li>
									<li>Equity/Disparities Coordinators</li>
								</ul>
							</p>
						</div>
						<div className="level">
							<div className="header">Instruction Level</div>
							<p>
								<ul>
									<li>Application</li>
								</ul>
							</p>
						</div>
					</div>
				</Col>
			</Row>
			<hr /> */}
			{/* <Row>
				<Col xl="3">
					<div className="upcoming-date-container">
						<div className="type">Videoconference</div>
						<div className="date">February 7, 2023</div>
						<div className="time">3 PM - 4 PM ET</div>
						<div className="series">2022-2023 Series (3)</div>
					</div>
					<div className="button-container">
						<MyButton
							variant="purple"
							onClick={() =>
								window.open(
									`https://www.tctcollab.com/assets/pdf/TCTC%202022-2023%20Videoconference%20Series_FNL1.pdf`,
									"_blank"
								)
							}
						>
							Series Overview
						</MyButton>
						<MyButton
							onClick={() =>
								window.open(
									`https://us02web.zoom.us/meeting/register/tZctdemhqjouH9a_JOf_ac1_bzi7eWypShAE`,
									"_blank"
								)
							}
						>
							Register Here!
						</MyButton>
						
					</div>
				</Col>
				<Col xl="9">
					<div className="upcoming-info-container">
						<div className="title">
							Commercial Tobacco Control's Place in Community-Driven Change and
							Power Building
						</div>

						<div className="objectives">
							<div className="header">Learning Objectives</div>
							<p>
								By the close of this videoconference, participants will be able
								to:
								<ul>
									<li>
										Articulate how community power building is fundamental and
										instrumental to transforming inequitable conditions in
										communities and addressing structural inequities
									</li>
									<li>
										Understand the rationale for, and impact of, multi-issue,
										multi-racial organizing for health and wellbeing in
										communities
									</li>
								</ul>
							</p>
						</div>
						
						<div className="target">
							<div className="header">Target Audience</div>
							<p>
								<ul>
									<li>All Staff</li>
								</ul>
							</p>
						</div>
						<div className="level">
							<div className="header">Instruction Level</div>
							<p>
								<ul>
									<li>Understanding</li>
								</ul>
							</p>
						</div>
					</div>
				</Col>
			</Row>
			<hr /> */}
			<Row>
				<Col xl="3">
					<div className="upcoming-date-container">
						<div className="type">Videoconference</div>
						<div className="date">March 7, 2023</div>
						<div className="time">3 PM - 4:30 PM ET</div>
						<div className="series">2022-2023 Series (4)</div>
					</div>
					<div className="button-container">
						<MyButton
							variant="purple"
							onClick={() =>
								window.open(
									`https://www.tctcollab.com/assets/pdf/TCTC%202022-2023%20Videoconference%20Series_FNL1.pdf`,
									"_blank"
								)
							}
						>
							Series Overview
						</MyButton>
						<MyButton
							onClick={() =>
								window.open(
									`https://us02web.zoom.us/meeting/register/tZcvfu-gqTspG9yWuwoM5HdPclKTm4qqSnHz`,
									"_blank"
								)
							}
						>
							Register Here!
						</MyButton>
	
					</div>
				</Col>

				<Col xl="9">
					<div className="upcoming-info-container">
						<div className="type"></div>
						<div className="title">
							Shifting an Equity Lens to our Data Practices: Data Disaggregation
							<div className="subtitle">
								A Collaborative Webinar with Asian Pacific Partners for
								Empowerment, Advocacy and Leadership (APPEAL)
							</div>
						</div>

						<div className="objectives">
							<div className="header">Learning Objectives</div>
							<p>
								By the close of this videoconference, participants will be able
								to:
								<ul>
									<li>
										Understand the link between data disaggregation and health
										equity
									</li>
									<li>
										Explain possible policy-based solutions related to data
										disaggregation
									</li>
									<li>
										Advocate internally for increased support of disaggregated
										data to drive decision-making and resource allocation to
										address commercial tobacco control-related health
										disparities
									</li>
								</ul>
							</p>
						</div>
						{/* <div className="speakers">
							<div className="header">Speakers</div>
							<p>
								<strong>
									Rod Lew <em>(Moderator)</em>
								</strong>{" "}
								Executive Director, Asian Pacific Partners for Empowerment,
								Advocacy and Leadership
								<br />
								<strong>
									<em>More TBD</em>
								</strong>
							</p>
						</div> */}
						<div className="target">
							<div className="header">Target Audience</div>
							<p>
								<ul>
									<li>Program Managers</li>
									<li>Equity/Disparities Coordinators</li>
									<li>Program Evaluators</li>
								</ul>
							</p>
						</div>
						<div className="level">
							<div className="header">Instruction Level</div>
							<p>
								<ul>
									<li>Application</li>
								</ul>
							</p>
						</div>
					</div>
				</Col>
			</Row>
		</Container>
	);
}
