import React from "react";
import { Button } from "react-bootstrap";
import MyButton from "./mybutton";
import { Form, Container, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useContext, useEffect } from "react";
import CoPLogo from "../img/coplogo.png";
import AuthContext from "../context/authprovider";
export default function CoP(props) {
	let params = useParams();
	const { auth, setAuth } = useContext(AuthContext);
	const [npm, setNpm] = useState(true);
	const [yc, setYc] = useState(false);

	// const [password,setPassword]=useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [message, setMessage] = useState("");
	const style = {
		maxWidth: "800px",
		textAlign: "center",
		margin: "auto",
		boxShadow: "5px 5px 5px grey",
	};
	useEffect(() => {
		let saved = localStorage.getItem("user");
		if (saved && JSON.stringify(auth) === "{}") {
			setAuth(JSON.parse(saved));
		}
		// console.log(auth.userid);
	}, []);
	const npmhandler = () => {
		setNpm(true);
		setYc(false);
		setMessage("");
	};
	const ychandler = () => {
		setNpm(false);
		setYc(true);
		setMessage("");
	};
	const handleSubmit = () => {
		let cop = 55;
		let eventname =
			"Policy and Strategy Thinking for State and Community Interventions";
		// let cop = 0;
		// let eventname = "";
		// if (npm) {
		// 	cop = 33;
		// 	eventname =
		// 		"New Commercial Tobacco Control Program Manager Community of Practice";
		// } else {
		// 	cop = 34;
		// 	eventname = "State Youth Coordinator Community of Practice";
		// }
		let data = {
			eventid: cop,
			userid: auth.userid,
			eventname,
			meetingid: 82572435577,
		};
		fetch(`https://www.api.tctcollab.com/event/cop/register`, {
			// fetch(`http://LocalHost:3001/event/cop/register`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then((data) => data.json())
			.then((data) => {
				setMessage(data);
			});
	};
	useEffect(() => {}, []);
	return (
		<div className="steps-container">
			<div style={{ marginBottom: "20px" }}>
				<Container>
					<Row>
						<div
							style={{
								width: "100%",
								textAlign: "center",
								marginBottom: "30px",
							}}
						>
							<img
								style={{ width: "50%", textAlign: "center" }}
								src={CoPLogo}
							/>
							<h3 style={{ marginTop: "30px" }}>
								Policy and Strategy Thinking for State and Community
								Interventions
							</h3>
							{/* <div>
								<h3 style={{ marginTop: "30px" }}>Select a CoP Below</h3>
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "stretch",
									}}
								>
									<div style={{ width: "30%" }}>
										<MyButton
											onClick={() => npmhandler()}
											variant={npm ? "blue" : "grey"}
										>
											New CTC Program Managers
										</MyButton>
									</div>{" "}
									<div style={{ width: "30%" }}>
										<MyButton
											onClick={() => ychandler()}
											variant={yc ? "green" : "grey"}
										>
											State Youth Coordinators
										</MyButton>
									</div>
								</div>
								<h4 style={{ color: "red" }}>
									<em>Registration is Now Closed</em>
								</h4>
							</div> */}
						</div>
						<h2 style={{ color: "blue", marginBottom: "30px" }}>
							{message !== "" && message}
						</h2>
					</Row>
					{/* <Row>
						<Col>
							<h3 style={{ marginBottom: "30px" }}>
								{{npm
									? "New Commercial Tobacco Control Program Manager Community of Practice Information"
									: "State Youth Coordinator Community of Practice Information"} }
								Policy and Strategy Thinking for State and Community
								Interventions
							</h3>
						</Col>
					</Row> */}

					<Row style={{}}>
						<div className="cop-container npm">
							<div className="header-text">Target Members</div>
							<p style={{ marginBottom: "30px" }}>
								This CoP is targeted to state health department staff involved
								in policy development and strategy for their state commercial
								tobacco control program.
							</p>
							<div className="header-text">Dates</div>
							<p style={{ marginBottom: "30px", textAlign: "center" }}>
								This CoP is a series of 4 sessions occuring on the dates below:
								<br />
								Wednesday, September 18, 2024
								<br />
								Wednesday, October 2, 2024 <br />
								Wednesday, October 16, 2024 <br />
								Wednesday, October 30 , 2024 <br />
								<div style={{ marginTop: "20px" }}>
									<strong>Note: </strong>
									<em>
										All sessions will be held on Wednesdays via Zoom at 1:00 PM
										EST - 2:30 PM EST
									</em>
								</div>
							</p>
							<div className="header-text">Objectives</div>
							<p>
								This CoP is dedicated to the practice of developing and
								coordinating activities related to Policy and Strategy Thinking
								for State and Community Interventions Meeting together with
								others involved in policy development and implementation for
								their state in the spirit of learning, knowledge sharing, and
								collaboration is the heart of this CoP. Therefore, the
								objectives of the four video-conference sessions will be to:
								<ul>
									<li>
										Develop skills to apply the evidence base to policy and
										strategy thinking;
									</li>
									<li>
										Ensure ability to work effectively with the local partners
										to recruit and engage a diverse group stakeholders for
										participating in statewide efforts;
									</li>
									<li>
										Explore strategies and tactics for addressing policy
										development and implementation challenges;
									</li>
									<li>
										Support improved policy development and strategic thinking
										skills and awareness of existing tools and resources to
										support success by providing a forum to identify solutions
										to common problems; and
									</li>
									<li>
										Provide the opportunity to innovate and create new ideas;
										validate and build on existing knowledge and good practice;
										and accelerate learning.
									</li>
								</ul>
							</p>

							<hr />
							{/* <p>
								<strong>IMPORTANT NOTE:</strong> Once you click the button,
								below you will <strong>INSTANTLY</strong> be registered for the{" "}
								<strong>
									Policy and Strategy Thinking for State and Community
									Interventions CoP
								</strong>
								. <strong>DO NOT</strong> click the button unless you are
								certain you want to and are able to attend at least{" "}
								<strong>three (3) sessions</strong>.
							</p>
							{JSON.stringify(auth) === "{}" ? (
								<Button variant="success" disabled>
									Please login to register for this CoP
								</Button>
							) : (
								<Button variant="success" onClick={() => handleSubmit()}>
									Register for the Policy and Strategy Thinking for State and
									Community Interventions CoP by Clicking Here
								</Button>
							)} */}
							<p>Registration is now closed.</p>
						</div>
						{/* ) : (
							<div className="cop-container yc">
								<div className="header-text">Target Members</div>
								<p style={{ marginBottom: "30px" }}>
									This CoP is targeted to state health department staff leading
									the youth programming work for their state commercial tobacco
									control program.
								</p>
								<div className="header-text">Dates</div>
								<p style={{ marginBottom: "30px", textAlign: "center" }}>
									This CoP is a series of 4 sessions occuring on the dates
									below:
									<br />
									October 19, 2023
									<br />
									October 26, 2023 <br />
									November 2, 2023 <br />
									November 9, 2023 <br />
									<div style={{ marginTop: "20px" }}>
										<strong>Note: </strong>
										<em>
											All sessions will be held on Thursdays via Zoom at 1:00 PM
											EST - 2:30 PM EST
										</em>
									</div>
								</p>

								<div className="header-text">Objectives</div>
								<p>
									This CoP is dedicated to the practice of developing and
									coordinating activities related to OSH Goal Area 1: prevent
									the initiation of tobacco use among youth and young adults via
									education and engagement to address the rapidly evolving
									tobacco product landscape for OSH-PSB's DP20-2001. Meeting
									together with others leading youth programming for their state
									in the spirit of learning, knowledge sharing, and
									collaboration is the heart of this CoP. Therefore, the
									objectives of the four video-conference sessions will be to:
									<ul>
										<li>
											Develop skills to utilize data and input from youth for
											creating youth program strategies;
										</li>
										<li>
											Ensure ability to work effectively with the local partners
											to recruit and engage a diverse group of young people for
											participating in statewide efforts;
										</li>
										<li>
											Explore strategies and tactics for addressing common youth
											engagement-related challenges;
										</li>
										<li>
											Support improved youth engagement skills and awareness of
											existing tools and resources to support youth programming
											success by providing a forum to identify solutions to
											common problems; and
										</li>
										<li>
											Provide the opportunity to innovate and create new ideas,
											validate and build on existing knowledge and good
											practice, and accelerate learning.
										</li>
									</ul>
								</p> */}

						{/* <hr /> */}
						{/* <p>
									<strong>IMPORTANT NOTE:</strong> Once you click the button,
									below you will <strong>INSTANTLY</strong> be registered for
									the <strong>State Youth Coordinator CoP</strong>.{" "}
									<strong>DO NOT</strong> click the button unless you are
									certain you want to attend.
								</p> */}
						{/* {JSON.stringify(auth) === "{}" ? (
									<Button variant="success" disabled>
										Please login to register for this CoP
									</Button>
								) : (
									<Button variant="success" onClick={() => handleSubmit()}>
										Register for the State Youth Coordinator CoP by Clicking
										Here
									</Button>
								)} */}
						{/* </div>
						)} */}
					</Row>

					<hr />

					<Row>
						<h2 style={{ color: "blue" }}>{message !== "" && message}</h2>
					</Row>
				</Container>
			</div>
		</div>
	);
}
