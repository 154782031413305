import React from "react";
// import { useEffect } from "react";
import * as d3 from "d3";
// import * as d3 from "https://cdn.jsdelivr.net/npm/d3@7/+esm";
import usJson from "../img/us.json";
// import * as d3scale from "d3-scale";
// import { axisLeft, axisBottom } from "d3-axis";

class Map extends React.Component {
	constructor(props) {
		super(props);
		this.state = { data: this.props.data };
		// useEffect(() => {}, [this.props]);
	}

	drawMap(data, click, title, headers) {
		// console.log(click);
		if (data !== 0) {
			var width = 960;
			var height = 500;
			var chart = d3
				.select("#dashboard-container")
				.append("div")
				.attr("id", this.props.id)
				.attr("class", "d3-chart-container");

			var svg = chart
				.append("svg")
				.attr("width", "100%")
				.attr("height", "100%");
			svg
				.append("text")
				// .attr("transform", "translate(70,0)")
				.attr("x", width / 2)
				.attr("y", 50)
				.attr("font-size", "20px")
				.attr("text-anchor", "middle")
				.text(title);
			var g = svg
				.append("g")
				.attr("transform", "translate(" + 0 + "," + 70 + ")");
			var projection = d3
				.geoAlbersUsa()
				.translate([width / 2, height / 2]) // translate to center of screen
				.scale([1000]);
			var path = d3
				.geoPath() // path generator that will convert GeoJSON to SVG paths
				.projection(projection);
			var hoverDiv = chart
				// .select(".d3-chart-container")
				.append("div")
				.attr("class", "tooltip-bar")
				.style("opacity", 0);
			var color = d3
				.scaleLinear()
				.domain([0, 20])
				.range(["#f7fcfd", "#00441b"]);

			var legendText = [
				"Cities Lived",
				"States Lived",
				"States Visited",
				"Nada",
			];

			var div = svg.append("div").attr("class", "tooltip").style("opacity", 0);

			//Loop through each state data value in the .csv file
			const json = this.props.data;
			// console.log(json);
			for (var i = 0; i < click.length; i++) {
				// Grab State Name

				var dataState = click[i].state;

				// Grab data value
				var dataValue = click[i].clicks;
				// console.log(json);
				// Find the corresponding state inside the GeoJSON
				for (var j = 0; j < json.features.length; j++) {
					var jsonState = json.features[j].properties.name;

					if (dataState == jsonState) {
						// Copy the data value into the JSON
						json.features[j].properties.visited = dataValue;
						// console.log(json.features);
						// Stop looking through the JSON
						break;
					}
				}
			}

			// Bind the data to the SVG and create one path per GeoJSON feature
			g.selectAll("path")
				.data(json.features)
				.enter()
				.append("path")
				.attr("d", path)
				.style("stroke", "#fff")
				.style("stroke-width", "1")
				// .style("fill", "lightblue")
				.style("fill", function (d) {
					// Get data value
					var value = d.properties.visited;

					if (value) {
						//If value exists…
						// console.log(color);

						return color(value);
					} else {
						//If value is undefined…
						return "rgb(213,222,217)";
					}
				})
				.on("mouseover", function (d) {
					d3.select(this).transition().duration("50").attr("opacity", ".85");

					hoverDiv
						.html(
							"<div> <strong>" +
								headers[0] +
								"</strong> " +
								d3.select(this).datum().properties.name +
								"</div><div> <strong>" +
								headers[1] +
								"</strong> " +
								d3.select(this).datum().properties.visited +
								"</div>"
						)
						.style("left", d3.pointer(d, ".bar")[0] + 10 + "px")
						.style("top", d3.pointer(d, ".bar")[1] - 15 + "px");
					hoverDiv.transition().duration(50).style("opacity", 1);

					// console.log(d3.select(this).datum().properties.visited);
				})
				.on("mouseout", function (d, i) {
					d3.select(this).transition().duration("50").attr("opacity", "1");
					hoverDiv.transition().duration("50").style("opacity", 0);
				});
		}

		// });
	}

	componentDidMount() {
		this.drawMap(
			this.props.data,
			this.props.clicks,
			this.props.title,
			this.props.headers
		);
	}
	componentDidUpdate(prevProps) {
		const element = document.getElementById(this.props.id);
		element.remove();
		this.drawMap(
			this.props.data,
			this.props.clicks,
			this.props.title,
			this.props.headers
		);
	}
	componentWillUnmount() {
		const element = document.getElementById(this.props.id);
		element.remove();
	}
	render() {
		return <div id={"#" + this.props.id}></div>;
	}
}

export default Map;
