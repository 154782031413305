import React, { useEffect, useState } from "react";
import Menu from "./menu";
import { Container, Row, Col, Form, Table } from "react-bootstrap";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import AuthContext from "../context/authprovider";
import { useContext, useCallback } from "react";
import { useLocation } from "react-router-dom";
export default function PHAOnboardingSurvey() {
	///PULL TL ID FROM CONTEXT
	const { auth } = useContext(AuthContext);
	const [userid, setUserid] = useState(auth.userid);
	const SURVEY_ID = 2;
	const surveyJson = {
		elements: [
			{
				type: "matrix",
				name: "matrix",
				title:
					"Please indicate if you agree or disagree with the following statements",
				columns: [
					{
						value: 5,
						text: "Strongly agree",
					},
					{
						value: 4,
						text: "Agree",
					},
					{
						value: 3,
						text: "Neutral",
					},
					{
						value: 2,
						text: "Disagree",
					},
					{
						value: 1,
						text: "Strongly disagree",
					},
				],
				rows: [
					{
						value: "1",
						text: "The content of the Onboarding Tool is adequate to sufficiently acclimate new program staff to the NTCP and OSH.",
					},
					{
						value: "2",
						text: "The TCTC Online Tool makes it easy to facilitate the Awardee Onboarding Process.",
					},
					{
						value: "3",
						text: "The Toolkit makes it easy for the Awardee to navigate the Onboarding Process.",
					},
					{
						value: "4",
						text: "As observed in your regular discussions with your PM, the Onboarding Process helps my Awardee acclimate their staff more quickly to their NTCP responsibilities.",
					},
				],
				alternateRows: true,
				isAllRowRequired: true,
			},
			{
				name: "5",
				title: "What do you most like about the Onboarding Tool?",
				type: "text",
			},
			{
				name: "6",
				title: "What do you dislike about the tool?",
				type: "text",
			},
			{
				name: "7",
				title:
					"Are there additional content areas that you think need to be added? If yes, please describe additional foundational content needs.",
				type: "text",
			},
		],
	};

	const survey = new Model(surveyJson);

	const saveSurveyResults = (sender, options) => {
		options.showSaveInProgress();
		console.log(sender.data);
		fetch("https://www.api.tctcollab.com/survey/store", {
			method: "POST",
			headers: {
				"Content-Type": "application/json;charset=UTF-8",
			},
			body: JSON.stringify({
				userid: auth.userid,
				pollid: SURVEY_ID,
				results: sender.data,
			}),
		})
			.then((response) => {
				// if (response) {
				// 	// Handle success
				// } else {
				// 	// Handle error
				// }
				console.log(response);
			})
			.catch((error) => {
				// Handle error
			});
	};
	// const surveyComplete = useCallback((sender) => {
	// 	saveSurveyResults("https://your-web-service.com/" + SURVEY_ID, sender.data);
	// }, []);
	survey.onComplete.add(saveSurveyResults);
	survey.onComplete.add((data) => console.log(data));
	return <Survey model={survey} />;
}
