import React from "react";
import { Button } from "react-bootstrap";
import { Form, Container, Row, Col, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useContext, useEffect } from "react";
import NNLogo from "../img/nnlogo.jpg";
import AuthContext from "../context/authprovider";

export default function NNMeeting(props) {
	let params = useParams();
	const { auth, setAuth } = useContext(AuthContext);
	const [message, setMessage] = useState("");
	const [orgType, setOrgType] = useState(-1);
	const style = {
		maxWidth: "800px",
		textAlign: "center",
		margin: "auto",
		boxShadow: "5px 5px 5px grey",
	};
	useEffect(() => {
		let saved = localStorage.getItem("user");
		if (saved && JSON.stringify(auth) === "{}") {
			setAuth(JSON.parse(saved));
		}
		// console.log(auth.userid);
		if (JSON.stringify(auth) === "{}") {
			// console.log(auth);console.log(false)
		} else {
			// console.log(auth)
			fetch(`https://www.api.tctcollab.com/user/org`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ userid: auth.userid }),
			})
				.then((data) => data.json())
				.then((data) => {
					// console.log(data);
					setOrgType(data.typeid);
				});
		}
	}, []);
	useEffect(() => {
		let saved = localStorage.getItem("user");
		if (saved && JSON.stringify(auth) === "{}") {
			setAuth(JSON.parse(saved));
		}
		// console.log(auth.userid);
		if (JSON.stringify(auth) === "{}") {
			// console.log(auth);console.log(false)
		} else {
			// console.log(auth)
			fetch(`https://www.api.tctcollab.com/user/org`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ userid: auth.userid }),
			})
				.then((data) => data.json())
				.then((data) => {
					// console.log(data);
					setOrgType(data.typeid);
				});
		}
	}, [auth]);
	const handleSubmit = () => {
		let eventid = 43;
		let eventname =
			"CDC'S National Networks Driving Action: Kicking Off CDC-RFA-DP-23-0015 Meeting";

		let data = { eventid, userid: auth.userid, eventname };
		fetch(`https://www.api.tctcollab.com/event/nn/register`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then((data) => data.json())
			.then((data) => {
				setMessage(data);
			});
	};
	return (
		<div className="nn-container">
			<Container>
				<Row>
					<div
						style={{
							width: "100%",
							textAlign: "center",
							marginBottom: "30px",
						}}
					>
						<img style={{ width: "30%", textAlign: "center" }} src={NNLogo} />
						<div>
							<h3 style={{ marginBottom: "30px" }}>
								CDC'S National Networks Driving Action:
								<br />
								Kicking Off CDC-RFA-DP-23-0015
							</h3>
							<h4>
								November 8, 2023 <br />
								1:00 PM EST - 5:00 PM EST
							</h4>
							<h4 style={{ color: "red" }}>
								<em>Registration is Now Closed.</em>
							</h4>
						</div>
					</div>
					<h2 style={{ color: "blue", marginBottom: "30px" }}>
						{message !== "" && message}
					</h2>
				</Row>
				{/* <Row>
					<Col>
						<h3 style={{ marginBottom: "30px" }}>
							CDC'S National Networks Driving Action:
							<br />
							Kicking Off CDC-RFA-DP-23-0015
						</h3>
					</Col>
				</Row> */}
				<Row style={{}}>
					{
						<div className="nn-container-two npm">
							<div className="header-text">Target Members</div>
							<p style={{ marginBottom: "30px" }}>
								This meeting is for key representatives from the nine National
								Networks under CDC-RFA-DP-23-0015.
							</p>
							<div className="header-text">Dates</div>
							<p style={{ marginBottom: "30px", textAlign: "center" }}>
								This meeting will occur on <strong>November 8, 2023</strong> at{" "}
								<strong>1:00 PM EST - 5:00 PM EST</strong> via Zoom.
							</p>
							<div className="header-text">Meeting Goal</div>
							<p style={{ textAlign: "left" }}>
								CDC's National Networks Driving Action: Kicking Off
								CDC-RFA-DP-23-0015 will bring together key representatives from
								the nine National Networks, CDC's Office on Smoking and Health
								(OSH) and Division of Cancer Prevention and Control (DCPC) to
								support successful implementation of CDC-RFA-DP-23-0015.
								Specifically, the four-hour virtual meeting will highlight a new
								population of focus and critical intersectional populations, as
								well as reflect the importance of the primary funding outcomes:
								<ul>
									<li>
										Increased participation in National Networks' Communities of
										Practice;{" "}
									</li>
									<li>
										Increased knowledge of strategies to address health
										disparities and promote health equity of the National
										Tobacco Control Program, National Comprehensive Cancer
										Control Program and other programs/partners through
										Networks' training and technical assistance; and{" "}
									</li>
									<li>
										Increased advancement of health equity through implementing
										(1) policy, systems and environmental (PSE) change
										interventions; (2) innovative interventions addressing the
										social determinants of health (SDoH); and, (3) mass reach
										health communication efforts.
									</li>
								</ul>
							</p>
							<div className="header-text">Meeting Objectives</div>
							<p style={{ textAlign: "left" }}>
								By the close of the meeting, participants will be able to:
								<ol>
									<li>
										Describe the purpose, approach, parameters and specific
										requirements of CDC-RFA-DP-23-0015;{" "}
									</li>
									<li>
										Identify best and promising practices for
										establishing/administering and managing a Community of
										Practice (CoP) focused on SDoH;{" "}
									</li>
									<li>
										Identify the importance of meeting state programs' health
										equity needs, especially as they relate to states' meeting
										their statewide commercial tobacco disparities requirement;
										and
									</li>
									<li>
										Engage new and strengthened networking relationships with
										colleagues across National Networks and CDC partners.{" "}
									</li>
								</ol>
							</p>
							<div className="header-text">Draft Meeting Agenda</div>
							<Table>
								<thead>
									<tr>
										<th style={{ width: "20%" }}>Time (EST)</th>
										<th>Agenda Item</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td style={{ fontWeight: "bold" }}>1:00 – 1:15</td>
										<td style={{ textAlign: "left" }}>
											<h5>Welcome</h5>
											<p>
												<em>
													Bonita McGee, facilitator <br />
													Tamatha Thomas-Haase, facilitator
												</em>
												<ul>
													<li>Agenda review </li>
													<li>Community expectations</li>
													<li>Large group virtual ice breaker</li>
												</ul>
											</p>
										</td>
									</tr>
									<tr>
										<td style={{ fontWeight: "bold" }}>1:15 – 1:35</td>
										<td style={{ textAlign: "left" }}>
											<h5>Setting the Tone for the Work Ahead</h5>
											<p>
												<em>
													{/* Deirdre Kittner, PhD, MPH, Director, Office on Smoking
													and Health <br /> */}
													Lisa Richardson, MD, MPH, Director, Division of Cancer
													Prevention and Control
												</em>
											</p>
										</td>
									</tr>
									<tr>
										<td style={{ fontWeight: "bold" }}>1:35 – 2:05</td>
										<td style={{ textAlign: "left" }}>
											<h5>
												Things to Know for a Successful First Year: Purpose,
												Approach, Parameters and Specific Requirements of
												CDC-RFA-DP-23-0015
											</h5>
											<p>
												<em>
													Chanel Recasner, Public Health Advisor, Office on
													Smoking and Health
													<br />
													Ena Wanliss, National Partnership Lead, Division of
													Cancer Prevention and Control
												</em>
											</p>
										</td>
									</tr>
									<tr>
										<td style={{ fontWeight: "bold" }}>2:05 – 3:20</td>
										<td style={{ textAlign: "left" }}>
											<h5>National Networks in Year One</h5>
											<ul>
												<li>Introductions</li>
											</ul>
											<h6>Group Discussion</h6>
											<p>
												<em>Bonita McGee, facilitator</em>
												<ul>
													<li>
														After hearing what is expected by CDC in the first
														year, what questions/clarifications do you
														have/need?{" "}
													</li>
													<li>
														What do you anticipate needing most from colleagues
														(other National Networks and CDC) to be successful
														in year one?{" "}
													</li>
													<li>
														How do we go deeper in our collaboration with one
														another to better drive equity and justice in
														commercial tobacco control?
													</li>
												</ul>
											</p>
										</td>
									</tr>

									<tr>
										<td style={{ fontWeight: "bold" }}>3:20 – 3:30</td>
										<td style={{ textAlign: "left" }}>
											<h5>BREAK</h5>
										</td>
									</tr>
									<tr>
										<td style={{ fontWeight: "bold" }}>3:30 – 4:10</td>
										<td style={{ textAlign: "left" }}>
											<h5>Best Practices for CoPs</h5>
											<h6>Group discussion</h6>
											<p>
												<em>Tamatha Thomas-Haase, facilitator</em>
												<ul>
													<li>
														National Networks that have collaborated to
														implement CoPs{" "}
														<ul>
															<li>What facilitates success?</li>
															<li>What are roadblocks?</li>
														</ul>
													</li>
													<li>
														What are critical elements of CoP
														discussion/activity when focusing on SDoH with
														states?
													</li>
												</ul>
											</p>
										</td>
									</tr>
									<tr>
										<td style={{ fontWeight: "bold" }}>4:10 – 4:50</td>
										<td style={{ textAlign: "left" }}>
											<h5>
												Meeting the Health Equity Needs of State Programs{" "}
											</h5>
											<h6>Group discussion</h6>
											<p>
												<em>Bonita McGee, facilitator</em>
												<ul>
													<li>
														What is needed by state programs to take their
														health equity work (e.g., meeting the statewide
														disparities requirement) to the next level?{" "}
														<ul>
															<li>
																If we create 3 categories of “progress” among
																state programs, what do states falling in each
																of these categories need most from Networks'
																training and technical assistance support?
															</li>
														</ul>
													</li>
													<li>
														What will the Networks do to guide and encourage
														continued momentum?
													</li>
												</ul>
											</p>
										</td>
									</tr>
									<tr>
										<td style={{ fontWeight: "bold" }}>4:50 – 5:00</td>
										<td style={{ textAlign: "left" }}>
											<h5>Closing Exercise and Evaluation</h5>
										</td>
									</tr>
								</tbody>
							</Table>
							<hr />
							{/* <div className="header-text">Register Here</div>
							<p>
								<strong>IMPORTANT NOTE:</strong> Once you click the button
								below, you will <strong>INSTANTLY</strong> be registered for the{" "}
								<strong>National Network Meeting</strong>.{" "}
								<strong>DO NOT</strong> click the button unless you are certain
								you want to attend.
							</p> */}
							{/* {JSON.stringify(auth) === "{}" ? (
								<Button variant="success" disabled>
									Please login to register for this National Network Meeting.
								</Button>
							) : orgType == 2 ? (
								<Button variant="success" onClick={() => handleSubmit()}>
									Register for the National Network Meeting by Clicking Here
								</Button>
							) : (
								<span style={{ color: "red", fontSize: "1.5em" }}>
									Your organization is not authorized to register. Please
									contact{" "}
									<a href="mailTo:support@tctcollab.com">
										support@tctcollab.com
									</a>{" "}
									if you believe you should have access.
								</span>
							)} */}
						</div>
					}
				</Row>
				<hr />

				<Row>
					<h2 style={{ color: "blue" }}>{message !== "" && message}</h2>
				</Row>
			</Container>
		</div>
	);
}
